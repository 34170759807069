import React, { useEffect, useRef, useState } from 'react';
import useContainerDimensions from '../hooks/useContainerDimensions.js';
import bioIcon from '../assets/images/bio-icon.svg';
import attributesIcon from '../assets/images/attributes.svg';
import baseKitIcon from '../assets/images/base-kit.svg';
import boostsIcon from '../assets/images/boosts.svg';
import './LayerImages.css';
import useSorterHelper from '../hooks/useSorterHelper.js';
import TraitCard from './TraitCard';



const LayerImages = ({metadata}) => {
  const [loading, setLoading] = useState(true);
  const [traitsVisible, setTraitsVisible] = useState(false);
  const [selectedTrait, setSelectedTrait] = useState("");
  const [showBackCard, setshowBackCard] = useState(false);
  const [mobileView, setmobileView] = useState(false);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const imageRef = useRef();
  const {baseKitArray, extrasArray, boostsArray } = useSorterHelper(metadata)
  // console.log(baseKitArray, extrasArray, boostsArray)
  const containerRef = useRef();
  const { width, height } = useContainerDimensions(containerRef);
  const [imageRatio, setImageRatio] = useState(0);
  const [resizerComponentSize, setResizerComponentSize] = useState({
    width: 0,
    height: 0,
  });
  const [containerSize, setContainerSize] = useState('medium');

  const cardRef = useRef(null);

  useEffect(() => {
    if (window.innerWidth < 900) {
      setmobileView(true);
    }

  }, []);

  // useEffect(() => {
  //   if (mouseActive) {
  //     document.getElementById('effect-box').addEventListener("mousemove", handleParallaxEffect)
  //   }
  //   return ()=> document.getElementById('effect-box').addEventListener("mousemove", handleParallaxEffect)
  // }, [mouseActive])

  const handleParallaxEffect = (e) => {
    let w = e.target.clientWidth;
    let h = e.target.clientHeight;
    let x = e.nativeEvent.offsetX;
    let y = e.nativeEvent.offsetY;
    // const parent = document.getElementById('effect-box')

    setMouseX(x - (w / 2));
    setMouseY(y - (h / 2));
    // document.querySelectorAll('#layer').forEach(layer => {
    // const speed = layer.getAttribute('data-speed')
    // const x = (parent.offsetWidth - e.pageX * speed) / 100;
    // const y = (parent.offsetHeight - e.pageY * speed) / 100;

    //   layer.style.transform = `translateX(${(((x - (w / 2)) / -25) * (2 * 0.7 + 1))}px) translateY(${(((y - (h / 2)) / -25) * (2 * 0.7 + 1))}px)`
    // })

    // console.log(w, h, 'valami')
  }
  const handleParallaxEnd = () => {
    // document.querySelectorAll('#layer').forEach(layer => {
    //   layer.style.transform = `translateX(${0}px) translateY(${0}px)`
    // })
    setMouseX(0)
    setMouseY(0)
  }
  const showTraits = (e) => {
    e.stopPropagation();
    setTraitsVisible(true);
    /* document.getElementById('explorer-scope').style.transform =
      'perspective(1000px) rotateY(180deg)'; */
    cardRef.current.style.transform = 'perspective(1000px) rotateY(180deg)';
  };
  const hideTraits = (e) => {
    e.stopPropagation();

    setshowBackCard(false);

    /*  document.getElementById('explorer-scope').style.transform =
       'perspective(1000px) rotateY(0deg)'; */
    cardRef.current.style.transform = 'perspective(1000px) rotateY(0deg)';
    setTimeout(() => {
      setSelectedTrait('')
    }, 1000);

    setTraitsVisible(false);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const setTheImageRatio = (width, height) => {
    setImageRatio(height / width);
  };
  useEffect(() => {
    if (imageRatio != 0) {
      setResizerComponentSize({
        width: width < height / imageRatio ? 100 + '%' : height / imageRatio,
        height: width < height / imageRatio ? width * imageRatio : 100 + '%',
      });
    }
  }, [imageRatio, width, height]);
  useEffect(() => {
    if (width) {
      let cSize = '';
      if (width > 500) {
        cSize = 'c-xlarge';
      } else if (width > 400) {
        cSize = 'c-large';
      } else if (width > 300) {
        cSize = 'c-medium';
      } else if (width > 240) {
        cSize = 'c-small';
      } else {
        cSize = 'c-xsmall';
      }
      setContainerSize(cSize);
    }
  }, [width]);
  return (
    <>
      <div
        className={`explorer-card-image-container ${containerSize}`}
        ref={containerRef}
        style={{ opacity: loading ? '0' : '1' }}
        onClick={(e) => stopPropagation(e)}
      >
        {/* <img src={trait_card} alt="" style={sx.image} /> */}
        <div className='explorer-pyramid_anim_container'>
          <div
            className='resizer-container'
            style={{
              opacity: resizerComponentSize.width === 0 ? 0 : 1,
              width: resizerComponentSize.width,
              height: resizerComponentSize.height,
            }}
          >
            <div
              ref={cardRef}
              className={`explorer-scope ${traitsVisible ? 'active' : ''}  `}
              style={{ width: '100%', height: '100%' }}
            >
              <span
                className={`explorer-front ${!traitsVisible ? 'active' : ''}`}
                id='explorer-front-span'
              >
                <div
                  id='effect-box'
                  onMouseLeave={handleParallaxEnd}
                  onMouseMove={handleParallaxEffect}

                >
                  <div className='explorer-flipped-datas-box' 
                  
                  style={{
                    transform:
                      `
                         translateX(${(mouseX / -25) * (5 * 0.4 + 1)}px) 
                         translateY(${(mouseY / -25) * (5 * 0.4 + 1)}px)
                         scale(${1 + (0.02 * 1) * (0)})
                         `}}>
                    <div className='explorer-token-id-box'>
                      <p>#{metadata.token_id}</p>
                    </div>
                    <div className='explorer-archetype-box'>
                      {metadata.archetype}
                    </div>
                    <div className='explorer-faction-box'>
                      {metadata.faction}
                    </div>
                  </div>
                  {metadata.layers.map((item, index) => (
                    <img
                      className={`explorer-flipped-img`}
                      id='layer'
                      src={item.image}
                      alt='not found'
                      ref={index === 0 ? imageRef : undefined}
                      // data-speed='3'
                      onLoad={() => {
                        if (index === 0) {
                          setTheImageRatio(
                            imageRef.current.naturalWidth,
                            imageRef.current.naturalHeight
                          );
                        }
                        if (index === metadata.layers.length - 1)
                          setLoading(false);
                      }}
                      style={{
                        transform:
                          `
                         translateX(${(mouseX / -25) * (index * 0.4 + 1)}px) 
                         translateY(${(mouseY / -25) * (index * 0.4 + 1)}px)
                         scale(${1 + (0.02 * 1) * (index === 0 ? 1 : 0)})
                         `}}
                      key={index}
                    />
                  ))}
                </div>
                <div
                  className={`explorer-trait-container ${traitsVisible ? 'hide' : ''}`}
                >
                  <div
                    className="explorer-trait-holder"
                    onClick={(e) => {
                      setSelectedTrait('bio');
                      showTraits(e);
                    }}
                  >
                    {' '}
                    <img
                      src={bioIcon}
                      alt="undefined"
                    />{' '}
                  </div>
                  <div
                    className="explorer-trait-holder"
                    onClick={(e) => {
                      setSelectedTrait('attributes');
                      showTraits(e);
                    }}
                  >
                    {' '}
                    <img
                      src={attributesIcon}
                      alt="undefined"
                    />{' '}
                  </div>
                  <div
                    className="explorer-trait-holder"
                    onClick={(e) => {
                      setSelectedTrait('base-kit');
                      showTraits(e);
                    }}
                  >
                    {' '}
                    <img
                      src={baseKitIcon}
                      alt="undefined"
                    />{' '}
                  </div>
                  <div
                    className="explorer-trait-holder"
                    onClick={(e) => {
                      setSelectedTrait('boosts');
                      showTraits(e);
                    }}
                  >
                    {' '}
                    <img
                      src={boostsIcon}
                      alt="undefined"
                    />{' '}
                  </div>
                </div>
              </span>

              <span
                className={`explorer-back ${traitsVisible ? 'active' : ''} `}
              >
                <TraitCard metadata={metadata}
                  type={selectedTrait}
                  baseKit={baseKitArray}
                  boosts={boostsArray}
                  extras={extrasArray}
                  onClick={hideTraits}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LayerImages;
