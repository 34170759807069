import React from 'react'
import bioIcon from '../../assets/images/bio-icon.svg';

const Bio = ({moniker, archetType, description}) => {
    return (
        <div className='back-content-holder'>
            <img src={bioIcon} alt="" className='back-bio-icon'/>
            <h3 className='back-title bio-title'>{archetType}</h3>
            <h4 className='back-subtitle bio-subtitle'>'{moniker}'</h4>
            <p className='back-description bio-description'>{description}</p>
        </div>
    )
}

export default Bio