import { useEffect, useState } from 'react';

const useSorterHelper = (metadata) => {
    const [baseKitArray, setbaseKitArray] = useState([]);
    const [extrasArray, setextrasArray] = useState([]);
    const [boostsArray, setboostsArray] = useState([]);
    // let attributesDatas = [];
    const baseKitDatas = [];
    const extrasDatas = [];
    const boostsDatas = [];

    useEffect(() => {
        return () => Object.keys(metadata).map((key) => {
            concatStringHelper(key, metadata[key]);
            console.log(metadata)
        });
    }, []);

    const concatStringHelper = (string, value) => {
        console.log(string, 'string: ', value, 'vlaue')
        let key;
        let tmpObject = {};
        if (string.startsWith('gear')) {
            let words = string.split('_');
            for (let i = 1; i < words.length; i++) {
                words[i] = words[i][0].toUpperCase() + words[i].substr(1);
            }
            key = words[1] + `${words[2] ? ' ' + words[2] : ''}`;
            tmpObject[key] = value;
            // console.log(tmpObject)
            baseKitDatas.push(tmpObject);
        }
        if (string.startsWith('extra')) {
            let words = string.split('_');
            for (let i = 1; i < words.length; i++) {
                words[i] = words[i][0].toUpperCase() + words[i].substr(1);
            }
            key = words[1] + `${words[2] ? ' ' + words[2] : ''}`;
            tmpObject[key] = value;
            // console.log(tmpObject)
            extrasDatas.push(tmpObject);
        }
        if (string.startsWith('boost')) {
            let words = string.split('_');
            for (let i = 1; i < words.length; i++) {
                words[i] = words[i][0].toUpperCase() + words[i].substr(1);
            }
            key = words[1] + `${words[2] ? ' ' + words[2] : ''}`;
            tmpObject[key] = value;
            // console.log(tmpObject)
            boostsDatas.push(tmpObject);
        } else if (string.startsWith('scene')) {
            let words = string;
            words = words.charAt(0).toUpperCase() + words.slice(1);
            tmpObject[words] = value;
            baseKitDatas.unshift(tmpObject);
        } else return;

        setbaseKitArray(baseKitDatas)
        setextrasArray(extrasDatas)
        setboostsArray(boostsDatas)
    };

    // console.log(baseKitArray,
    //     extrasArray,
    //     boostsArray);
    return {
        baseKitArray,
        extrasArray,
        boostsArray
    };
};

export default useSorterHelper;
