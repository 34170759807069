import React, { useEffect, useState } from 'react';
import close from '../assets/images/white_close.svg';
import Attributes from './back-types/Attributes';
import BaseKit from './back-types/BaseKit';
import Bio from './back-types/Bio';
import Boosts from './back-types/Boosts';
import './TraitCard.css';

const TraitCard = ({ onClick, metadata, baseKit, extras, boosts, type }) => {
    const renderSelectedBackCard = () => {
        switch (type) {
            case 'bio':
                return <Bio archetType={metadata.archetype} moniker={metadata.moniker} description={metadata.description} />
                break;
            case 'attributes':
                return <Attributes metadata={metadata} />
                break;
            case 'base-kit':
                return <BaseKit baseKitArray={baseKit} extrasArray={extras} />
                break;
            case 'boosts':
                return <Boosts boostsArray={boosts} />
                break;
            default:
                break;
        }
    }

    return (
        <>
            <div className='explorer-back-background'>
                    {metadata.layers.map((item, index) => (
                        <img src={item.image} alt="" key={index} className='explorer-flipped-img' style={{transform: 'rotateY(180deg)'}}/>
                    ))}
                <img
                    className='explorer-close-button'
                    src={close}
                    alt=''
                    onClick={(e) => onClick(e)}
                />
                {type !== '' && <div className='card-overlay' style={{ zIndex: 2 }}>
                    {renderSelectedBackCard()}
                </div>
                }
            </div>
        </>
    );
};

export default TraitCard;
