import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// import LayerImages from './LayerImages.jsx';
import ClipLoader from 'react-spinners/ClipLoader';
import LayerImages from './LayerImages';
import metadata from '../metadata/metadata.json'

const CardRender = () => {
    const location = useLocation();
    const metadataURL = location.search.slice(1);
    // console.log(location);
    // console.log(metadataURL);
    // const [metadata, setmetadata] = useState(undefined);
    const [loading, setLoading] = useState(true)
    // console.log(metadata[metadataURL])
    // useEffect(() => {
    //     (async () => {
    //         axios.get(`https://${metadataURL}`).then((response) => {
    //             setmetadata(response.data);
    //             setLoading(false)
    //         }).catch((e) => {
    //             console.log(e);
    //         });
    //     })();
    // }, []);
    useEffect(() => {
      if(metadata[metadataURL]){
        setLoading(false);
      }
    }, [])
    
    return (
        <>
         <ClipLoader
                size={150}
                color="rgb(252, 108, 3)"
                loading={loading}
                css={{
                    position: 'absolute',
                    border: '5px solid rgb(252, 108, 3)',
                    borderBottomColor: 'transparent',
                    margin: 'auto',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}
                speedMultiplier={0.5}
            />
            <LayerImages metadata={metadata[`${metadataURL}`]}/>
        </>
    )
}

export default CardRender